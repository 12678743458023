<template>
  <v-card flat tile>
    <v-card-text class="pt-0 pb-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('model.PostEventNotifications.type') }}
              </th>
              <th class="text-left">
                {{ $t('model.PostEventNotifications.url') }}
              </th>
              <th class="text-left">
                {{ $t('model.PostEventNotifications.action') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(conversionNotification, index) in selectedObject.conversionNotifications"
              :key="index"
            >
              <td style="width: 200px" v-if="conversionNotification.isNew">
                <v-select
                  class="mt-5 pt-0"
                  :items="notificationTypes"
                  :value="conversionNotification.type"
                  @input="
                    updateAttributeOnArrayNested({
                      key: 'conversionNotifications',
                      index: index,
                      attributeKey: 'type',
                      attributeValue: $event,
                    })
                  "
                  solo-inverted
                  dense
                  flat
                  small-chips
                  :label="$t('model.PostEventNotifications.type')"
                >
                  <template v-slot:item="data">
                    <v-chip
                      label
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      class="ma-1"
                      color="transparent"
                    >
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      label
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      class="ma-1"
                      color="transparent"
                    >
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                </v-select>
              </td>
              <td style="width: 200px; height: 80px" v-else>
                <v-chip label class="ma-1">
                  {{ notificationType(conversionNotification.type) }}
                </v-chip>
              </td>
              <td v-if="conversionNotification.isNew">
                <v-text-field
                  :ref="`url${index}`"
                  class="mt-5 pt-0"
                  :value="conversionNotification.url"
                  @input="
                    updateAttributeOnArrayNested({
                      key: 'conversionNotifications',
                      index: index,
                      attributeKey: 'url',
                      attributeValue: $event,
                    })
                  "
                  solo-inverted
                  dense
                  flat
                  :label="fieldLabelByType(conversionNotification.type)"
                  :required="true"
                  :rules="[
                    (v) => !!v || $t('commons.rules.errorRequired'),
                    (v) =>
                      validateUrlOrImgTag(v, conversionNotification.type) ||
                      errorMessageByType(conversionNotification.type),
                  ]"
                />
                <macros-glossary
                  v-if="RegexTypes.RegexUrl.test(conversionNotification.url)"
                  @addMacros="addMacros(index, conversionNotification.url, $event)"
                />
              </td>
              <td v-else>{{ conversionNotification.url }}</td>
              <td style="width: 100px; vertical-align: top; padding-top: 25px">
                <pcc-button-tooltip
                  v-on:click="deleteOnArrayNested({ key: 'conversionNotifications', index: index })"
                  :icon="Icons.delete"
                  position="bottom"
                  :tooltip="$t('commons.operation.delete')"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions v-if="selectedObject.conversionNotifications.length < 3">
      <v-spacer />
      <pcc-button-tooltip
        v-on:click="
          addToArrayNested({
            key: 'conversionNotifications',
            value: { type: 'IMAGE_PIXEL', url: '', isNew: true },
          })
        "
        :icon="Icons.add"
        position="top"
        :tooltip="$t('commons.operation.add')"
      />
      <v-spacer />
    </v-card-actions>
    <v-alert v-else type="warning" elevation="0" colored-border text>
      {{ $t('model.AffiliatePixels.limitReached') }}
    </v-alert>
  </v-card>
</template>
<script>
import PccButtonTooltip from './PccButtonTooltip';
import MacrosGlossary from './MacrosGlossary';
import RegexTypes from '../../store/constants/RegexTypes';

export default {
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
    'macros-glossary': MacrosGlossary,
  },
  data: function () {
    return {
      RegexTypes,
      notificationTypes: [
        { value: 'IMAGE_PIXEL', text: 'Image Pixel' },
        { value: 'SERVER_TO_SERVER_METHOD_GET', text: 'Postback URL' },
      ],
    };
  },
  props: {
    selectedObject: Object,
  },
  methods: {
    addToArrayNested(event) {
      this.$emit('addToArrayNested', event);
    },
    updateOnArrayNested(event) {
      this.$emit('updateOnArrayNested', event);
    },
    updateAttributeOnArrayNested(event) {
      this.$emit('updateAttributeOnArrayNested', event);
    },
    deleteOnArrayNested(event) {
      this.$emit('deleteOnArrayNested', event);
    },
    notificationType(original) {
      const conversionObject = {
        IMAGE_PIXEL: 'Image Pixel',
        SCRIPT_PIXEL: 'Script Pixel',
        SERVER_TO_SERVER_METHOD_GET: 'Postback URL',
        SERVER_TO_SERVER_METHOD_POST: 'Postback POST',
      };
      return conversionObject[original];
    },
    addMacros(index, url, macros) {
      if (macros.length > 0 && RegexTypes.RegexUrl.test(url)) {
        const urlWithMacros =
          url.substr(0, this.$refs[`url${index}`][0].$refs.input.selectionStart) +
          macros +
          url.substr(this.$refs[`url${index}`][0].$refs.input.selectionEnd);
        this.updateAttributeOnArrayNested({
          key: 'conversionNotifications',
          index: index,
          attributeKey: 'url',
          attributeValue: urlWithMacros,
        });
      }
    },
    validateUrlOrImgTag(url, type) {
      if (type === 'IMAGE_PIXEL') {
        return RegexTypes.ImgTag.test(url);
      }
      return RegexTypes.RegexUrl.test(url);
    },
    fieldLabelByType(type) {
      if (type === 'IMAGE_PIXEL') {
        return this.$t('model.AffiliatePixels.fields.imageCode');
      }
      if (type === 'SCRIPT_PIXEL') {
        return this.$t('model.AffiliatePixels.fields.script');
      }
      return this.$t('model.AffiliatePixels.fields.url');
    },
    errorMessageByType(type) {
      if (type === 'IMAGE_PIXEL') {
        return this.$t('commons.rules.errorInvalidCode');
      }
      if (type === 'SCRIPT_PIXEL') {
        return this.$t('commons.rules.errorInvalidScript');
      }
      return this.$t('commons.rules.errorInvalidUrl');
    },
  },
};
</script>
